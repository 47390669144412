import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard, IRoleGuarded, ClaimValues } from '@mt-ng2/auth-module';
import { CustomersComponent } from './customers/customer-list/customers.component';
import { AppSettingsComponent } from './app-settings.component';
import { ClaimTypes } from '@system-select/model';
import { UserService } from '@system-select/web-services';
import { IEntityRouteConfig } from '@mt-ng2/entity-components-base';
import { UserDetailComponent } from './users/user-detail/user-detail.component';
import {
    LoginOldStyleComponent,
    ForgotPasswordOldStyleComponent,
    ResetPasswordOldStyleComponent,
    AdminAccessComponent,
} from '@mt-ng2/login-module';

const homeRoleGuard: IRoleGuarded = {
    claimType: ClaimTypes.Customers,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    isHomePage: true,
};

const userEntityConfig: IEntityRouteConfig = {
    addressesPath: 'addresses',
    claimType: ClaimTypes.Users,
    documentsPath: 'documents',
    entityIdParam: 'userId',
    notesPath: 'notes',
    service: UserService,
};

const title = 'Admin Portal';
const appRoutes: Routes = [
    { path: 'login', component: LoginOldStyleComponent, title: title, },
    { path: 'forgotpassword', component: ForgotPasswordOldStyleComponent, title: title, },
    { path: 'resetpassword', component: ResetPasswordOldStyleComponent, title: title, },
    { path: 'adminaccess', component: AdminAccessComponent, title: title, },
    {
        canActivate: [AuthGuard],
        component: CustomersComponent,
        data: homeRoleGuard,
        title: title,
        path: 'home',
    },
    {
        canActivate: [AuthGuard],
        component: AppSettingsComponent,
        title: title,
        path: 'settings',
    },
    {
        canActivate: [AuthGuard],
        component: UserDetailComponent,
        data: userEntityConfig,
        path: 'my-profile',
        title: title,
        pathMatch: 'full',
    },
    {
        canActivate: [AuthGuard],
        component: CustomersComponent,
        data: homeRoleGuard,
        title: title,
        path: '**',
    },
];

@NgModule({
    exports: [RouterModule],
    imports: [
        RouterModule.forRoot(appRoutes, { onSameUrlNavigation: 'reload' }),
    ],
})
export class AppRoutingModule { }
